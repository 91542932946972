webClient.controller('Wonderbox.headController', ['$scope', '$window', '$timeout', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', function ($scope, $window, $timeout, tseApiClient, tseUtil, airHelper) {
    $window.$headScope = $scope;
    $scope.site = $window.site;    
    var pageName = $window.site.pageName;

    if (pageName == "AirBookingConfirmation" ||
        pageName == "AirBookingPayment" ||
        pageName == "AirPaymentResponseBody3DSBack" ||
        pageName == "AirPaymentResponseBody3DSRedirection") {
        $scope.homeUrl = "";
    }
    else if (pageName == "AirBookingPaymentOKResponse" ||
        pageName == "AirPaymentResponseBody3DSKO" ||
        pageName == "AirPaymentResponseBody3DSKO" ||
        pageName == "AirBookingPaymentKOResponse" ||
        pageName == "AirBookingPaymentWarningResponse") {

        var loginUrl = $window.site.loginUrl;
        $scope.homeUrl = loginUrl.replace(loginUrl.slice(loginUrl.indexOf('?')), "");
    }
    else {
        $scope.homeUrl = $window.site.loginUrl;
    }
    console.log($scope.homeUrl);
}]);