function FSelectMPG_Refresh() {
    var oText = document.getElementById('i-travellers-text');
    var oSelectMPG = document.getElementById('i-select-mpg');

    if (oSelectMPG) {
        var aInputs = oSelectMPG.getElementsByTagName('input');
        var aOutput = new Array();
        var vTotal = 0;
        var vTotalAdult = 0;
        var vTotalBaby = 0;
        var oSearchWhoField = document.getElementById('i-search-who-field');
        var oSelectMPGErr = document.getElementById('i-select-mpg-err');

        //myObj.addEventListener("mousedown", function(e) { XLObjTarget(e) ;} );

        for (i = 0; i < aInputs.length; i++) {
            vInputVal = aInputs[i].value;
            if (i == 0 && vInputVal == 1) {
                aOutput.push(getT('homepage.oneadulte')), vTotalAdult = vTotalAdult + parseInt(vInputVal);
                oSelectMPG.getElementsByClassName("c-pax-adults")[0].innerText = getT('resultpage.adult');
            }
            else if (i == 0 && vInputVal > 1) {
                aOutput.push(vInputVal + ' ' + getT('loadingpage.adults')), vTotalAdult = vTotalAdult + parseInt(vInputVal);
                oSelectMPG.getElementsByClassName("c-pax-adults")[0].innerText = getT('resultpage.adults');
            }
            else if (i == 1 && vInputVal == 1) {
                aOutput.push('1 ' + getT('loadingpage.child'));
                oSelectMPG.getElementsByClassName("c-pax-children")[0].innerText = getT('resultpage.child') + getT('homepage.searchform.selectpassengers.childrenage');
            }
            else if (i == 1 && vInputVal > 1) {
                aOutput.push(vInputVal + ' ' + getT('loadingpage.children'));
                oSelectMPG.getElementsByClassName("c-pax-children")[0].innerText = getT('resultpage.children') + getT('homepage.searchform.selectpassengers.childrenage');
            }
            else if (i == 2 && vInputVal == 1) {
                aOutput.push('1 ' + getT('loadingpage.infant')), vTotalBaby = vTotalBaby + parseInt(vInputVal);
                oSelectMPG.getElementsByClassName("c-pax-infants")[0].innerText = getT('resultpage.infant') + getT('homepage.searchform.selectpassengers.infantsage');
            }
            else if (i == 2 && vInputVal > 1) {
                aOutput.push(vInputVal + ' ' + getT('loadingpage.infants')), vTotalBaby = vTotalBaby + parseInt(vInputVal);
                oSelectMPG.getElementsByClassName("c-pax-infants")[0].innerText = getT('resultpage.infants') + getT('homepage.searchform.selectpassengers.infantsage');
            }
            vTotal = vTotal + parseInt(vInputVal);
        }

        //console.log(vTotal) ;

        oSelectMPGErr.classList.remove('c-show');
        oSearchWhoField.removeAttribute('data-error');
        //oSelectMPGErr.classList.remove('c-ani-shake') ;

        if (vTotal > 9) {
            oSelectMPGErr.innerHTML = '9' + getT('error.airsearchrequest.maxpassengersboxreached');
            oSearchWhoField.setAttribute('data-error', '9' + getT('error.airsearchrequest.maxpassengersboxreached'));
            oSelectMPGErr.classList.add('c-show');
            //oSelectMPGErr.classList.add('c-ani-shake') ;
            XLClassAnim({ oObj: oSelectMPGErr, vClass: 'c-ani-shake', vTime: 500 });

        }

        if (oSearchWhoField.hasAttribute("data-max")) {
            maxPax = oSearchWhoField.getAttribute("data-max");
            oWhoPop = document.getElementById("i-search-who-pop");
            aPaxPlusBtn = oWhoPop.getElementsByClassName("c-p");
            if (vTotal > maxPax) {
                if (maxPax == 1) {
                    oSelectMPGErr.innerHTML = maxPax + getT('error.airsearchrequest.maximumpassengerreached');
                    oSearchWhoField.setAttribute('data-error', maxPax + + getT('error.airsearchrequest.maximumpassengerreached'));
                }
                else if (maxPax > 1) {
                    oSelectMPGErr.innerHTML = maxPax + getT('error.airsearchrequest.maximumpassengersreached');
                    oSearchWhoField.setAttribute('data-error', maxPax + getT('error.airsearchrequest.maximumpassengersreached'));
                }
                oSelectMPGErr.classList.add('c-show');
                XLClassAnim({ oObj: oSelectMPGErr, vClass: 'c-ani-shake', vTime: 500 });

                if (vTotal >= maxPax) {
                    for (var i = 0; i < aPaxPlusBtn.length; i++) {
                        aPaxPlusBtn[i].style.pointerEvents = "none";
                    }
                }
            }
            else {
                for (var i = 0; i < aPaxPlusBtn.length; i++) {
                    aPaxPlusBtn[i].style.pointerEvents = "inherit";
                }
            }
        }

        if (vTotalBaby > vTotalAdult) {
            oSelectMPGErr.innerHTML = getT('airbooking.adultforinfant');
            oSearchWhoField.setAttribute('data-error', getT('airbooking.adultforinfant'));
            oSelectMPGErr.classList.add('c-show');
            XLClassAnim({ oObj: oSelectMPGErr, vClass: 'c-ani-shake', vTime: 500 });
        }

        //oText.value = aOutput.join(' + ') ;
        oText.innerHTML = aOutput.join(' + ');
    }
}
onReady(function () {
    if (document.getElementById("i-search")){
        var oFieldOrigin = document.getElementById('i-where-origin');
        oFieldOrigin.value = '';
        oFieldOrigin.classList.add("customSearchOri");
        var oFieldDest = document.getElementById('i-where-destination');
        oFieldDest.value = '';
        oFieldDest.classList.add('customSearch');
        var oFieldCabinClass = document.getElementsByName('cabinClass');
        oFieldCabinClass[0].value = site.loginInfo.CabinClasses[0];
        $.each($(".c-search .c-title .c-select"), function (index, value) {
            value.removeAttribute("onclick");
        });
        document.getElementById("i-search-who-field").setAttribute("data-max", site.loginInfo.Passengers);
        document.getElementById("i-search-who-field").setAttribute("data-min", site.loginInfo.Passengers);
        var whoPop = document.getElementById("i-search-who-pop");
        if (whoPop) {
            aSelectPax = whoPop.getElementsByClassName("c-select-mp");
        
            for (var i = 0; i < aSelectPax.length; i++) aSelectPax[i].setAttribute("data-max", site.loginInfo.Passengers);
            if (site.loginInfo.Passengers > 1 && document.getElementById("i-search-who-pop")) {
                document.getElementById("i-search-who-pop").getElementsByClassName("c-p")[0].click();
            }
        }
    }
});
var V_CS_AIRPORTSORI = '';
if (site.loginInfo && site.loginInfo.OriginCities) var V_CS_CITIESORI = site.loginInfo.OriginCities;
var V_CS_GROUPORI = '1';
var V_CS_REMOVE_IDENTORI = '1';
var V_CS_AIRPORTS = '';
if (site.loginInfo && site.loginInfo.DestinationCities) var V_CS_CITIES = site.loginInfo.DestinationCities;
var V_CS_EXCLUDE_IATAS = '';
var V_CS_GROUP = '1';
var V_CS_REMOVE_IDENT = '1';
function upperCaseF(a) {
    setTimeout(function () {
        a.value = a.value.toUpperCase();
    }, 1);
}